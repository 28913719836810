import React, { useEffect } from 'react';
import Grid from "@mui/material/Grid";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";

import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';
import Linkbar from '../../../components/linkbar/Linkbar';
import BannerLogo from '../../../components/bannerlogo/BannerLogo';

const MyEbooks = () => {
    const [myEbooks, setMyEbooks] = React.useState([]);

    useEffect(() => {
        const token = document.cookie.split('; ').find(row => row.startsWith('session=')).split('=')[1];

        fetch('https://api.lideratech.com.br/api/v1/ebooks', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log(data);
                setMyEbooks(data);
            })
            .catch(error => {
                console.error('Erro ao obter os eBooks:', error);
            });
    }, []);

    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.slice(0, maxLength) + '...';
        } else {
            return text;
        }
    };

    return (
        <>
            <Header />
            <Linkbar />
            <div>
                <h1>Meus eBooks</h1>
                <p>Aqui você encontra todos os eBooks que você está cadastrados.</p>
                <Paper
                    sx={{
                        p: 10,
                        margin: 'auto',
                        maxWidth: 1000,
                    }}
                >
                    <Grid container spacing={3} justifyContent="center">
                        {myEbooks.length > 0 ? (
                            myEbooks.map((eBook, index) => (
                                <Grid item key={index} xs={12} sm={6} md={6} lg={4}>
                                    <Paper>
                                        <CardMedia component="img" height="150" image="https://source.unsplash.com/random" alt="random" />
                                        <CardContent sx={{ overflow: 'auto', maxWidth: 500 }}>
                                            <Typography gutterBottom variant="h5" component="div">
                                                {eBook.name}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {truncateText(eBook.description, 60)}
                                            </Typography>
                                            <br />
                                            <Button variant="contained" color="success">
                                                Go to eBook
                                            </Button>
                                        </CardContent>
                                    </Paper>
                                </Grid>
                            ))
                        ) : (
                            <Grid item xs={12}>
                                <Typography align="center" variant="h5">
                                    Você ainda não comprou nenhum eBook.
                                </Typography>
                                <br />
                                {/*redirect to /ebooks*/}
                                <Button variant="contained" color="success" onClick={() => window.location.href = '/ebooks'}>
                                    Ache um eBook que faça sentido.
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Paper>
            </div>
            <BannerLogo />
            <Footer />
        </>
    );
}

export default MyEbooks;
