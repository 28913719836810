// check using Fetch API to call docker-compose called core
// to check if token storaged in cookie session
// is valid or not

import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import * as Cookies from "js-cookie";
import axios from 'axios';

class AuthCheck extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            loading: true
        }
    }

    componentDidMount() {

        const token = Cookies.get('session');

        const CallCheckAuth = async () => {

            const result = await axios.post(`${process.env.REACT_APP_API_URL}/auth/check`, {
                token: token
            })
            .then(response => {
                // Check if token is valid or not
                if (response.status === 200) {
                    console.log('Token is Valid');
                } else {
                    console.log('Token is Invalid');
                }
            })
            .catch(error => {
                console.log("can't access API: error, ", error);
            });

            
            return result
        }
        
        CallCheckAuth();
    }

    render() {
        const { loading, redirect } = this.state;
        if (loading) {
            return null;
        }
        if (redirect) {
            return <Navigate to="/login" />
        }
        return this.props.children;
    }
}

export default AuthCheck;
