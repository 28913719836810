import React, { useEffect } from 'react';
import Grid from "@mui/material/Grid";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { Link } from 'react-router-dom';

import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';
import Linkbar from '../../../components/linkbar/Linkbar';
import BannerLogo from '../../../components/bannerlogo/BannerLogo';

import './MyCourses.css';


const MyCourses = () => {
    const [myCourses, setMyCourses] = React.useState([]);

    useEffect(() => {
        const token = document.cookie.split('; ').find(row => row.startsWith('session=')).split('=')[1];

        console.log('Token:', token)

        fetch(process.env.REACT_APP_API_URL + '/api/v1/coursesbought', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log(data);
                setMyCourses(data);
            })
            .catch(error => {
                console.error('Erro ao obter os cursos:', error);
            });
    }, []);

    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.slice(0, maxLength) + '...';
        } else {
            return text;
        }
    };

    return (
        <>
            <Header />
            <Linkbar />
            <div>
                <h1>Meus Cursos</h1>
                <p>Aqui você encontra todos os cursos que você está cadastrados.</p>
                <Paper sx={{p: 10, margin: 'auto', maxWidth: 1000}}>
                    <Grid container spacing={3} justifyContent="center">
                        {myCourses.length > 0 ? (
                            myCourses.map((course, index) => (
                                <Grid item key={index} xs={12} sm={6} md={6} lg={4}>
                                    <Paper>
                                        <CardMedia component="img" height="auto" image={course.banner} alt={course.name} />
                                        <CardContent sx={{overflow: 'auto', maxWidth: 500 }}>
                                            <Typography gutterBottom variant="h5" component="div">
                                                {course.name}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {truncateText(course.description, 60)}
                                            </Typography>
                                            <br />
                                            <Link to={`/mycourses/${course.id}`} className="link">
                                                Go to course...
                                            </Link>
                                        </CardContent>
                                    </Paper>
                                </Grid>
                            ))
                        ) : (
                            <Grid item xs={12}>
                                <Typography align="center" variant="h5">
                                    Você ainda não comprou nenhum curso.
                                </Typography>
                                <br />
                                <Button variant="contained" color="success" onClick={() => window.location.href = '/courses'}>
                                    Ache um curso que faça sentido.
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Paper>
            </div>
            <BannerLogo />
            <Footer />
        </>
    );
}

export default MyCourses;
