import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

import Footer from '../../../components/footer/Footer';
import BannerLogo from '../../../components/bannerlogo/BannerLogo';

import { getAuth, signInWithPopup, signInWithEmailAndPassword } from "firebase/auth";
import GoogleIcon from '@mui/icons-material/Google';
import Cookies from "js-cookie"; // Importação correta do Cookies

import { GoogleAuthProvider } from "firebase/auth";

const provider = new GoogleAuthProvider();
const authGoogle = getAuth();

// const authGoogleLogin = () => {
//     return signInWithPopup(authGoogle, provider)
//         .then((result) => {
//             const credential = GoogleAuthProvider.credentialFromResult(result);
//             const token = credential.accessToken;
//             console.log("Token Google:", token);
//             const user = result.user;
//
//             // Salva o token de acesso no cookie
//             setSessionCookie(token);
//             console.log("Usuário logado:", user);
//
//             return true;
//         }).catch((error) => {
//             console.log("Houve um erro na autenticação:", error.code, error);
//             return false;
//         });
// };

const authGoogleLogin = () => {
    return signInWithPopup(authGoogle, provider)
        .then(async (result) => {
            const user = result.user;
            const token = await user.getIdToken(); // Obtém o IdToken
            console.log("Token Google:", token);

            // Salva o token de acesso no cookie
            setSessionCookie(token);
            console.log("Usuário logado:", user);

            return true;
        }).catch((error) => {
            console.log("Houve um erro na autenticação:", error.code, error);
            return false;
        });
};

export const setSessionCookie = (session) => {
  Cookies.remove("session");
  Cookies.set("session", session, { expires: 2 });
  console.log("Cookie salvo com token de sessão");
};

const defaultTheme = createTheme();

export default function Login() {
  const navigate = useNavigate();

  const handleGoogleLogin = async () => {
      const result = await authGoogleLogin();
      if (result) {
          navigate('/dashboard'); // Redireciona após o login com Google
      } else {
          console.log('Google Login failed');
      }
  };

  const handleLogin = async (event) => {
    event.preventDefault();

    try {
        const data = new FormData(event.currentTarget);
        const email = data.get('email');
        const password = data.get('password');

        const userCredential = await signInWithEmailAndPassword(authGoogle, email, password);
        const user = userCredential.user;
        const userToken = await user.getIdToken();

        // Salva o token de sessão
        setSessionCookie(userToken);

        navigate('/dashboard'); // Redireciona após o login com email e senha

    } catch (error) {
        alert('Não foi possível fazer o login, verifique se o email e senha estão corretos');
        console.error('Erro ao fazer login:', error.code, error.message);
    }
  };  

  return (
      <>
        <ThemeProvider theme={defaultTheme}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
                <BannerLogo />

              <Typography component="h2" variant="h4">
                Login
              </Typography>
              <Box component="form" onSubmit={handleLogin} noValidate sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Mantenha conectado"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Logar
                </Button>
                <Grid container>
                  <Grid item xs>
                    <Link href="#" variant="body2">
                      Esqueceu a senha?
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link href="/registry" variant="body2">
                      {"Não tem conta? Registre-se"}
                    </Link>
                  </Grid>
                </Grid>
              </Box>

              <Divider sx={{ width: '100%', mt: 3, mb: 3 }}>OU</Divider>

              <Button
                onClick={handleGoogleLogin}
                fullWidth
                variant="outlined"
                sx={{
                  mb: 2,
                  color: '#4285F4',
                  borderColor: '#4285F4',
                  textTransform: 'none',
                  fontSize: '16px',
                  fontWeight: 'bold',
                }}
                startIcon={<GoogleIcon />}
              >
                Sign in with Google
              </Button>
            </Box>
          </Container>
        </ThemeProvider>
        <Footer />
      </>
  );
}
